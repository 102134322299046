import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/section';
import Wrapper from '../components/Wrapper';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <Section>
        <h1>NOT FOUND</h1>
        <p>Unfortunately, you have reached a page that doesn't exist. Please use the links above or below to navigate elsewhere.</p>
      </Section>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
