/** @jsx jsx */
import { jsx } from 'theme-ui';
import { lighten } from '@theme-ui/color';
import { formatMoney } from '../lib/utilities';

const DonationItem = ({ index, value, message, selected }) => {
  const styles = selected
    ? {
      borderColor: 'blue',
      opacity: 1
    }
    : {};

  return (
    <label
      sx={{
        cursor: "pointer",
        // "input[type=radio]:checked + .donation-item": {
        //   borderColor: "blue",
        //   opacity: 1,
        // },
      }}
    >
      {/* <input
        type="radio"
        name="donation-item"
        sx={{
          border: 0,
          clip: "rect(1px, 1px, 1px, 1px)",
          height: "1px",
          overflow: "hidden",
          padding: 0,
          position: "absolute !important",
          width: "1px",
        }}
      /> */}
      <div
        className="donation-item"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          backgroundColor: lighten("gray", 0.2 - index / 10),
          borderRadius: "10px",
          boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
          p: [3, 4],
          height: "100%",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          border: "3px solid",
          borderColor: "transparent",
          opacity: 0.85,
          transition: "150ms",
          ":hover": {
            opacity: 1,
          },
          ...styles
        }}
      >
        <div
          sx={{
            width: "100%",
            fontSize: 4,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Kongrads
        </div>
        <div
          sx={{
            width: "100%",
            fontSize: [6, 7],
            textAlign: "center",
            color: "white",
          }}
        >
          {formatMoney(value / 100)}
        </div>
        <div
          sx={{
            width: "100%",
            textAlign: "center",
            color: "white",
            opacity: 0.9,
          }}
        >
          {message}
        </div>
      </div>
    </label>
  )
}

export default DonationItem;