import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { Spinner } from 'theme-ui';
// import { useSelector } from 'react-redux';
// import { useManualQuery } from 'graphql-hooks';

import NotFound from './404';
import Grad from '../components/Grad';

// const query = `
//   query($userId: Int!) {
//     getPublicProfile(userId: $userId) {
//       user_id
//       first_name
//       last_name
//       school
//       grad_date
//       profile_pic
//     }
//   }
// `;

export default function Grads() {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  // const user = useSelector(state => state.user);
  // const [fetchProfile] = useManualQuery(query);

  useEffect(() => {
    // if(user && window.location.pathname.match(/\/grads\/(.+)\/(.+)/)) {
    //   // go fetch the public profile
    //   fetchProfile({ variables: { userId: user.id } })
    //     .then(response => {
    //       setData(response.data.getPublicProfile);
    //       setLoaded(true);
    //     });

    // } else {
      const { __USER_PROFILE__: preload = null } = window;

      if(preload) {
        setData(preload);
      }
  
      setLoaded(true);
    // }
  }, []);

  if(!loaded) return <Spinner/>;

  if(data === null) return <NotFound/>;

  return (
    <Router basepath="grads">
      <Grad profile={data} path=":slug/:id"/>
    </Router>
  );
}