/** @jsx jsx */
import { jsx } from 'theme-ui';

const ProfileCard = ({
  profileImage,
  graduateName,
  schoolName,
  graduationDate
}) => (
  <div
    sx={{
      display: "flex",
      flexDirection: ["column", "row"],
      alignItems: "center",
      p: [3, 4],
      background: "white",
      borderRadius: "3px",
      maxWidth: ["100%", "500px"],
    }}
  >
    {profileImage && (
      <img
        src={profileImage}
        alt={graduateName}
        title={graduateName}
        sx={{
          borderRadius: "50%",
          display: "block",
          size: ["100px", "128px"],
        }}
      />
    )}
    <ul
      sx={{
        listStyle: "none",
        m: 0,
        pt: [3, 0],
        pl: [0, 4],
        fontSize: [2, 3],
      }}
    >
      <li>Graduate: {graduateName}</li>
      <li sx={{ mt: 1 }}>School: {schoolName}</li>
      <li sx={{ mt: 1 }}>Graduation: {graduationDate}</li>
    </ul>
  </div>
)

export default ProfileCard;