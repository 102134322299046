/** @jsx jsx */
import React, { useState } from 'react'; // eslint-disable-line
import { jsx, Button, Heading } from "theme-ui"
import { useMutation } from 'graphql-hooks';
import { useForm, FormContext } from 'react-hook-form';
import { view, lensPath } from 'rambda';

import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  // CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

// import GraphQLForm from './forms/GraphQLForm';
import Wrapper from './Wrapper';
import DonationItem from './DonationItem';
// import FormItem from './FormItem';
import InputWrap from './forms/InputWrap';
import Layout from './layout';
import PageWrap from './PageWrap';
import ProfileCard from './ProfileCard';
import TextArea from './forms/TextArea';
import TextInput from './forms/TextInput';

import { useSnackbar } from '../lib/hooks';
import { formatMoney } from '../lib/utilities';
import { gift as giftSchema } from '@bit/morleytatro.main.kongrads-schemas';

import iconCheckmark from "../images/checkmark.svg"

import './Grad.css';

// const getUserQuery = `
//   query($userId: Int!) {
//     getPublicProfile(userId: $userId) {
//       first_name
//       last_name
//       school
//       grad_date
//       profile_pic
//       stripe_acct
//     }
//   }
// `;

// const donateQuery = `
//   mutation($userId: Int!, input DonationInput!) {
//     makeDonation(userId: $userId, input: $input) {
//       message
//     }
//   }
// `;

// const sendMoneyQuery = `
//   mutation($input: GiftInput!) {
//     sendMoney(input: $input) {
//       message
//     }
//   }
// `;

const createPaymentIntentQuery = `
  mutation($input: PaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      client_secret
    }
  }
`;

const donationLevels = [
  {
    amount: 2500,
    message: 'Cum Laude'
  },
  {
    amount: 5000,
    message: 'Magna Cum Laude'
  },
  {
    amount: 10000,
    message: 'Summa Cum Laude'
  }
];

export default function Grad({ profile }) {
  // const { data, loading } = useQuery(getUserQuery, {
  //   variables: {
  //     userId: +id
  //   }
  // });

  const { first_name, last_name } = profile;

  const fullName = [first_name, last_name].join(' ');

  return (
    <Layout>
      <PageWrap title={fullName + ' is Graduating'}>
        <Wrapper>
          <Elements stripe={loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)}>
            <Inner profile={profile} fullName={fullName}/>
          </Elements>
        </Wrapper>
      </PageWrap>
    </Layout>
  );
}

function Inner({ profile, fullName }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [giftSubmitted, setGiftSubmitted] = useState(false);

  const {
    first_name,
    school,
    grad_date,
    profile_pic
  } = profile;

  let date = '';

  if(grad_date) {
    date = new Date(isNaN(grad_date) ? grad_date : +grad_date)
      .toLocaleDateString();
  }
  
  return (
    <>
      <ProfileCard
        profileImage={profile_pic}
        graduateName={fullName}
        schoolName={school}
        graduationDate={date}
      />
      <div
        sx={{
          p: [3, 4],
          background: "white",
          borderRadius: "3px",
          boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
          marginTop: '25px'
        }}
      >
        <Wrapper>
          This year <span sx={{ borderBottom: '1px solid', borderBottomColor: 'baseColor.grayDark' }}>{school}</span> may not have its traditional commencement ceremony, but {first_name} is still graduating none the less. Help {first_name} celebrate this important milestone with a gift.
        </Wrapper>
      </div>
      <Heading as="h3" sx={{ textAlign: "center", mt: 6, mb: 5 }}>
        Please Select an Amount to Give:
      </Heading>
      <div
        sx={{
          p: [3, 4],
          background: "white",
          borderRadius: "3px",
          boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
        }}
      >
        <div
          action=""
          sx={{
            "[data-reach-menu-button]": {
              variant: "forms.input",
            },
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row"],
              justifyContent: "center",
              alignItems: ["center", "center", "stretch"],
            }}
          >
            {donationLevels.map((level, i) => (
              // eslint-disable-next-line
              <div
                key={i}
                sx={{
                  width: "100%",
                  maxWidth: ["340px", "340px", "auto"],
                  "& + &": { mt: [4, 4, 0], ml: [0, 0, 4] },
                }}
                onClick={() => setSelectedIndex(i)}
              >
                <DonationItem
                  value={level.amount}
                  message={level.message}
                  index={i}
                  selected={selectedIndex === i}
                />
              </div>
            ))}
          </div>
          {giftSubmitted === false && (
            <GiftForm
              userId={profile.user_id}
              amount={donationLevels[selectedIndex].amount}
              setGiftSubmitted={setGiftSubmitted}
            />
          )}
          {/* <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              mt: [5, 6],
            }}
          >
            <GraphQLForm
              query={donateQuery}
              defaultValues={{}}
              validationSchema={{}}
              successMessage="Thank you for your donation!"
              buttonText="Send Now"
            >
              <div sx={{ flexGrow: 1, maxWidth: "500px" }}>
                <TextInput
                  label="Last Name"
                  name="last_name"
                />
                <TextInput
                  label="Email"
                  name="email"
                  type="email"
                />
                <TextArea
                  label="Message"
                  name="message"
                />
                {/* <FormItem label="Message">
                  <Textarea rows="3" />
                </FormItem> */}
                {/* <FormItem label="Card Number">
                  <Input type="text" />
                </FormItem>
                <FormItem label="Expiration Date">
                  <Input type="text" />
                </FormItem>
              </div> */}
              {/* <div sx={{ display: "flex", mt: [4, 0], ml: [0, 4] }}>
                <Button type="submit" sx={{ alignSelf: "flex-end" }}>
                  Send Now
                </Button>
              </div> */}
            {/* </GraphQLForm> */}
          {/* </div> */}
        </div>
      </div>
      {giftSubmitted && <SuccessPayment sx={{ mt: 6 }} />}
    </>
  )
}

const SuccessPayment = props => (
  <div
    sx={{
      width: "100%",
      maxWidth: "540px",
      mx: "auto",
      border: "1px solid",
      borderColor: "grayLight",
      p: 4,
      boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
      background: "white",
      position: "relative",
      borderRadius: "3px"
    }}
    {...props}
  >
    <div
      sx={{
        size: "60px",
        borderStyle: "solid",
        borderWidth: "0 0 1px 1px",
        borderColor: "grayLight",
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: "0 0 0 100%",
      }}
    >
      <img
        src={iconCheckmark}
        alt="Payment successful"
        title="Payment successful"
        sx={{
          display: "block",
          width: "35px",
          position: "absolute",
          right: "5px",
          top: "10px",
        }}
      />
    </div>
    <Heading as="h3" sx={{ textAlign: "center", fontSize: [4, 5], mb: 4 }}>
      Success
    </Heading>
    <Heading as="h3" sx={{ textAlign: "center", fontSize: [3, 4], my: 0 }}>
      Payment successful!
    </Heading>
  </div>
)

function GiftForm({ amount, userId, setGiftSubmitted }) {
  const { openSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [sendMoney] = useMutation(sendMoneyQuery);
  const [createPaymentIntent] = useMutation(createPaymentIntentQuery);
  
  const methods = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      message: '',
    },
    validationSchema: giftSchema
  });

  async function onSubmit(values) {
    if(isSubmitting || !stripe) return;

    setIsSubmitting(true);

    try {

      const { data } = await createPaymentIntent({
        variables: {
          input: {
            user_id: userId,
            amount,
            ...values
          }
        }
      });

      const result = await stripe.confirmCardPayment(
        data.createPaymentIntent.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: [values.first_name, values.last_name].join(' '),
              email: values.email,
              address: {
                postal_code: values.zip
              }
            }
          }
        }
      );

      // console.log(result);
      setIsSubmitting(false);

      const status = view(lensPath(['paymentIntent', 'status']), result);
      const errorMsg = view(lensPath(['error', 'message']), result);

      if(status === 'succeeded') {
        // do something here to indicate successful gift
        openSnackbar('Thank you. You have successfully sent ' + formatMoney(result.paymentIntent.amount / 100) + '!');
        setGiftSubmitted(true);
      } else if(errorMsg) {
        openSnackbar(errorMsg);
      }

    } catch(e) {
      console.log(e);
      openSnackbar(e.message);
    }

    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardElement),
    // });
    // const card = elements.getElement(CardElement);
    // const { error, token } = await stripe.createToken(card);

    // if(error) {
    //   // snackbar it
    //   openSnackbar(error.message);
    // } else {
    //   // send a GraphQL mutation to the backend
    //   setIsSubmitting(true);
    //   // console.log(paymentMethod, userId, amount);
    //   console.log(token);
    //   sendMoney({
    //     variables: {
    //       input: {
    //         user_id: userId,
    //         amount,
    //         token: token.id,
    //         ...values
    //       }
    //     }
    //   })
    //     .then(data => {
    //       console.log(data);
    //       setIsSubmitting(false);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //       setIsSubmitting(false);
    //       // let's navigate the person to the confirmation page
    //     })
  }

  const options = {
    style: {
      base: {
        fontSize: '16px',
        color: '#4c5267',
        fontFamily: '"Nunito Sans", sans-serif',
        fontWeight: 'normal'
      }
    }
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            mt: [5, 6],
          }}
        >
          <div sx={{ flexGrow: 1, maxWidth: ["100%", "100%", "500px"] }}>
            <TextInput
              label="First Name"
              name="first_name"
            />
            <TextInput
              label="Last Name"
              name="last_name"
            />
            <TextInput
              label="Email"
              name="email"
              type="email"
            />
            <TextArea
              label="Message"
              name="message"
              rows="4"
            />
            <InputWrap name="card" label="Card Number">
              <CardNumberElement options={options}/>
            </InputWrap>
            <InputWrap name="expiration" label="Expiration">
              <CardExpiryElement options={options}/>
            </InputWrap>
            <InputWrap name="cvc" label="Security Code">
              <CardCvcElement options={options}/>
            </InputWrap>
            <TextInput
              name="zip"
              label="Billing Zip Code"
            />
            <div style={{ marginTop: '20px' }}>
              <Button
                sx={{ alignSelf: "flex-end" }}
                disabled={!stripe || isSubmitting}
              >
                {isSubmitting ? 'Processing... Please wait' : 'Send Now'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormContext>
  )
}